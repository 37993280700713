<template>
  <div>
    <!--<filter-bar name="status" :pSearch="search"></filter-bar>-->

    <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center">
      <div class="bg-white w-fullrounded-lg shadow">
        <div class="h-20 py-3 flex items-center border-gray-200 p-6">
          <div class="flex">
            <div class="text-2xl font-bold text-green-700">{{$t('status')}}</div>
          </div>
          <!--div class="w-full flex justify-end">
            <router-link
              class="flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              to="/status/new"
            >
              <i class="material-icons">add</i> add status
            </router-link>
          </div-->
        </div>
        <div>
          <table class="min-w-full">
      <thead>
        <tr>
          <th
            v-for="(item, key) in headers"
            :key="key"
            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
          >
            {{ $t(item) }}
          </th>
          <th
            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>

      <tbody class="bg-white">
        <tr v-for="(item, index) in content" :key="index">
          <td
            v-for="(it, ind) in headers"
            :key="ind"
            class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
          >
        
            <div v-if="it == 'color' && name == 'status'">
              <p>
                <label class="inline-flex items-center mt-3">
                  <div
                    v-bind:style="{ background: content[index][it] }"
                    class="rounded-full h-5 w-5 flex items-center justify-center"
                  />
                </label>
              </p>
            </div>

            <div v-else class="text-sm capitalize leading-5 text-gray-900">
              {{ content[index][it] }}
            </div>
          </td>
          <td
            v-if="name != 'productsOrder'"
            class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
          >
            <div class="flex justify-end">
              <router-link
                v-if="getAccess(name, 'update') && name != 'statusHistories'"
                :class="$colors.actionTable"
                class="rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1"
                :to="`${name}/edit/${content[index]['_id']}`"
              >
                <i class="material-icons mx-2">edit</i>
              </router-link>
              <button class="justify-end"  @click="remove(content[index]['_id'])"><i class="material-icons mx-2">delete</i></button>


            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="content.length <= 0" class="bg-blue-100 py-8">
      <span
        class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
      >
        <p class="font-semibold flex text-green-500  whitespace-no-wrap">
          <i class="material-icons mx-3">error</i>
          <span class="">{{$t('no_rows_selected')}} </span>
        </p>
      </span>
    </div>

        </div>

        <div class="p-1">
          <div class="flex flex-col items-center my-12">
            <div v-if="paginate.lastpage > 1" class="flex text-gray-700">
              <button
                v-if="paginate.currentpage > 1"
                @click="prev"
                :disabled="paginate.currentpage == 1"
                class="h-8 w-8 focus:outline-none mr-6 flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_left </i>
              </button>
              <div class="flex h-8 font-medium rounded-full bg-gray-200">
                <div
                  v-if="paginate.currentpage != paginate.lastpage"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-green-600  text-white"
                >
                  {{ paginate.currentpage }}
                </div>
                <div
                  v-else
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  <span v-if="paginate.currentpage != 1"> 1 </span>
                </div>

                <div
                  v-if="paginate.currentpage >= 1 && paginate.lastpage != 1"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  ...
                </div>

                <button
                  :class="
                    paginate.currentpage == paginate.lastpage
                      ? 'bg-green-600  text-white'
                      : ' '
                  "
                  class="w-8 md:flex justify-center items-center hover:bg-green-600  hover:text-white focus:outline-none hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  {{ paginate.lastpage }}
                </button>
              </div>
              <button
                v-if="paginate.currentpage < paginate.lastpage"
                :disabled="paginate.currentpage == paginate.lastpage"
                @click="next"
                class="h-8 w-8 ml-6 focus:outline-none flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_right </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      currentUser: {
        role: {},
      },
      name:'status',
      content: [],
      headers: ["name", "fees", "color"],
      status: [],
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
    };
  },
  async mounted() {
    if (this.currentUser.type == "Admin" || this.currentUser.type == "CEO") await this.getRoles();

    await this.getUser();
    await this.getStatus({ limit: this.limit });
  },

  filters: {},
  methods: {
    async remove(id) {
      console.log('id____________',id)
      this.$confirm(this.$t('you_sure')).then(async (res) => {
          if (res) {
              const data = await this.$server.delete("status", { _id: id });
              if (data._id) {
                  alert(`${this.name} deleted !`, "success");
                  await this.refresh();
              }
          }
      });
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
    },
    
    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.currentUser.role.permissions;

      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];

        if (model == modelToCheck) {
          return access[accessToCheck];
        }
      }
    },
    async getStatus(filters) {
      const res = await this.$server.search("status", filters);

      if (res.content && res.content.results) {
        this.content = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.content = [];
      }
    },
    async refresh() {
      this.getStatus({ limit: this.limit });
    },

    async assign() {},

    async search(data) {
      const filter = {
        name: data,
      };
      const res = await this.$server.find("status", filter);
      //console.log(res);
      if (res.content) this.content = res.content;
      else this.content = [];
    },
    /*********************************************************************************/
    async next() {
      const filters = {
        page: ++this.paginate.currentpage,
        limit: this.limit,
      };
      await this.getStatus(filters);
    },
    async prev() {
      const filters = {
        page: --this.paginate.currentpage,
        limit: this.limit,
      };
      await this.getStatus(filters);
    },
  },
};
</script>

<style>
</style>